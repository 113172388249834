<!-- 

<div class="container-carousel" >
  <swiper class="swiper-container" [config]="config">
    <h1 class="title">Conheça a opinião dos nossos clientes</h1>

    <div class="swiper-wrapper">
      <div class="swiper-slide slide" 
        *ngFor="let client of clients; let index = index">
            <mat-icon>account_circle</mat-icon>
            <p>{{client.description}}</p>
            <small>{{client.name}}</small>
            <small>{{client.role}}</small>
      </div>
    </div>

    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  <!-- <div class="swiper-pagination"></div> 
</swiper>
</div> -->

<div class="container-carousel" >
  <h1 class="title">Conheça a opinião dos nossos clientes</h1>

  <swiper class="swiper-container" [config]="config">
    <div class="swiper-wrapper">
      <div class="swiper-slide slide" 
        *ngFor="let client of clients; let index = index">
          <div class="content"> 
            <mat-icon>account_circle</mat-icon>
            <p>{{client.description}}</p>
            <small>{{client.name}}</small>
            <small>{{client.role}}</small>
          </div>
      </div>
    </div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  
  </swiper>
</div>
