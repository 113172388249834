<div class="container">
	<section class="home">
		<div class="container__home">
			<div >
				<h1 class="home__title">Crédito para impulsionar sua empresa</h1>
				<p class="home__subtitle">Seja uma das mais de 1400 empresas que já anteciparam na plataforma digital de recebíveis número um do Brasil.</p>
				<button mat-flat-button color="primary" onclick="window.open('https://register.broadfactor.com/cedente')">Cadastre-se agora</button>
				<a mat-button [routerLink]="['solucoes/antecipar-recebiveis']">Saiba mais</a>

			</div>
			<img width="auto" height="auto" src="./assets/img/fogete-site.webp" alt="broadfactor home imagem">
		</div>

		<app-partners></app-partners>

	</section>


	<section class="container__info">
		<div class="container__info--width">
			<img width="300px" style="margin-right: 40px;" src="./assets/img/Homem.webp" alt="">
			<div class="container-info--text">
				<h1 class="home__title">Seja um financiador parceiro da Broadfactor</h1>
				<p class="home__subtitle">Compre títulos com segurança na plataforma que já realizou mais de R$350 milhões em antecipações.</p>
				<button mat-flat-button onclick="window.open('https://register.broadfactor.com/comprador')" color="primary">Quero ser um parceiro</button>
				<a mat-button [routerLink]="['solucoes/comprar-recebiveis']">Saiba mais</a>

			</div>
		</div>
	</section>

	<section class="container-benefits">
		<div class="container-benefits__width">
			<div class="container-benefits__grid">
				<div class="container-benefits__box">
					<h2>Por que antecipar com a Broadfactor?</h2>
				</div>
				<div class="container-benefits__box">
					<img alt="Totalmente online e digital" src="./assets/img/Totalmente online e digital.svg">
					<h3>Totalmente online e digital</h3>
					<span>Cadastro, análise, aprovação, assinatura de documentos e negociação, tudo de forma online e segura.</span>
				</div>
				<div class="container-benefits__box">
					<img alt="Crédito rápido" src="./assets/img/Credito rapido.svg">
					<h3>Crédito rápido</h3>
					<span>Ferramentas que conectam você a mais de 50 parceiros prontos para seus recebíveis.
					</span>
				</div>
				<div class="container-benefits__box">
					<img alt="Baixa inadimplência" src="./assets/img/Baixa inadimplencia.svg">
					<h3>Baixa inadimplência</h3>
					<span>Porcentagem mais baixa do mercado atual.</span>
				</div>
				<div class="container-benefits__box">
					<img alt="Atendimento exclusivo" src="./assets/img/Atendimento exclusivo.svg">
					<h3>Atendimento exclusivo</h3>
					<span>Suporte rápido e humanizado com apoio da tecnologia, sem bots ou robôs.</span>
				</div>
				<div class="container-benefits__box">
					<img alt="Inteligência Artificial" src="./assets/img/Inteligencia Artificial.svg">
					<h3>Inteligência Artificial</h3>
					<span>Recomendação de compra através de tecnologia.</span>
				</div>
			</div>
		</div>
	</section>

	<section class="container__home">
		<div>
			<h1 class="home__title">A primeira bolsa digital de recebíveis do Brasil</h1>
			<p class="home__subtitle">Nossa plataforma é pioneira no processo online de antecipação de recebíveis, pronta para ajudar pequenas e médias empresas a alcançar voos mais altos.</p>
			<div style="display: flex">
				<button mat-flat-button onclick="window.open('https://register.broadfactor.com/cedente')" color="primary">Pedir Antecipação</button>
				<button mat-stroked-button onclick="window.open('https://register.broadfactor.com/comprador')" color="primary" style="margin-left: 8px;">Seja um financiador </button>
			</div>
		</div>
		<app-lottie json="assets/lottie/home_1.json"></app-lottie>
	</section>

	<app-carousel></app-carousel>

	<section class="container__blog">
		<h2>Artigos que podem te ajudar:</h2>
		<div class="container__blog__grid">
			<div>
				<img src="./assets/img/O que é antecipacao de recebiveis.png" alt="O que é antecipacao de recebiveis">
				<b>Entenda o que é antecipação de recebíveis</b>
				<p>Para explicar de forma simples, clara e direta, antecipar recebíveis é um procedimento que concede às empresas adiantar o dinheiro que ainda iriam receber…</p>
				<button mat-stroked-button onclick="window.open('https://broadfactor.com/blog/entenda-o-que-e-antecipacao-de-recebiveis/')">Continue lendo</button>
			</div>
			<div>
				<img src="./assets/img/6 motivos para escolher.png" alt="6 Motivos para escolher uma empresa de antecipação de recebíveis">
				<b>6 Motivos para escolher uma empresa de antecipação de recebíveis</b>
				<p>Aumentar os negócios e desfrutar de oportunidades para se fortalecer no mercado são aspectos importantes para qualquer empresa. </p>
				<button mat-stroked-button onclick="window.open('https://broadfactor.com/blog/6-motivos-para-escolher-uma-empresa-de-antecipacao-de-recebiveis/')">Continue lendo</button>
			</div>
			<div>
				<img src="./assets/img/AntecipaGov.png" alt="O que é AntecipaGov?">
				<b>O que é AntecipaGov? </b>
				<p>Com o intuito de ajudar e melhorar a relação com os fornecedores de produtos e/ou serviços para o Governo Federal, a União criou um Portal de Crédito Digital que se chama AntecipaGov.</p>
				<button mat-stroked-button onclick="window.open('https://broadfactor.com/blog/o-que-e-antecipa-gov/')">Continue lendo</button>
			</div>
		</div>
	</section>
</div>

