<div class="container">
	<section class="container__info">
		<div class="container__info--width">
			<div class="container-info--text">
				<h1 class="home__title">Antecipe recebíveis com facilidade!</h1>
				<p class="home__subtitle">Venda a prazo recebendo à vista.</p>
				<button mat-flat-button color="primary" onclick="window.open('https://register.broadfactor.com/cedente')">Pedir antecipação</button>
			</div>
			<video  width="auto" height="240" controls  poster="./assets/img/Thumb.png" >
				<source src="./assets/img/Video Ricardo - Antecipacao de recebiveis.mp4" type="video/mp4" rel="preload">
			  Your browser does not support the video tag.
			</video>
		</div>
	</section>

	<section class="container-benefits">
		<div class="container-benefits__width">
			<div class="container-benefits__grid">
				<div class="container-benefits__box">
					<h2>Por que antecipar com a Broadfactor?</h2>
				</div>
				<div class="container-benefits__box">
					<img alt="Totalmente online e digital" src="./assets/img/Totalmente online e digital.svg">
					<h3>Totalmente online e digital</h3>
					<span>Cadastro, análise, aprovação, assinatura de documentos e negociação, tudo de forma online e segura.</span>
				</div>
				<div class="container-benefits__box">
					<img alt="Crédito rápido" src="./assets/img/Credito rapido.svg">
					<h3>Crédito rápido</h3>
					<span>Ferramentas que conectam você a mais de 50 parceiros prontos para seus recebíveis.
					</span>
				</div>
				<div class="container-benefits__box empty">

				</div>
				<div class="container-benefits__box">
					<img alt="Atendimento exclusivo" src="./assets/img/Atendimento exclusivo.svg">
					<h3>Atendimento exclusivo</h3>
					<span>Suporte rápido e humanizado com apoio da tecnologia, sem bots ou robôs.</span>
				</div>
				<div class="container-benefits__box">
					<img alt="Inteligência Artificial" src="./assets/img/Inteligencia Artificial.svg">
					<h3>Inteligência Artificial</h3>
					<span>Recomendação de compra através de tecnologia.</span>
				</div>
			</div>
		</div>
	</section>

	<section class="container__home">
		<img src="./assets/img/png/antecipacao-site.png" width="70%" alt="">
		<div>
			<h1 class="home__title">O que é a antecipação de recebíveis?</h1>
			<p class="home__subtitle">
				<p>A antecipação é um recurso financeiro para empresas que desejam receber antecipadamente por vendas  parceladas.</p>
				Ao vender suas notas fiscais de serviços/produtos já fornecidos, é possível <b>receber o dinheiro da venda em até 24 horas</b> e aliviar o fluxo de caixa da sua empresa.

		</div>
	</section>

	<section class="company-requirements">
		<h2>Confira se a sua empresa está apta para antecipar recebíveis:</h2>
		<div class="company-requirements__block">
			<div class="company-requirements__item">
				<mat-icon>apartment</mat-icon>
				<p>Vende para Pessoa Jurídica</p>
			</div>
			<div class="company-requirements__item">
				<mat-icon>payments</mat-icon>
				<p>Faturamento mínimo de 50 mil mensais</p>
			</div>
			<div class="company-requirements__item">
				<mat-icon>receipt</mat-icon>
				<p>Deseja antecipar nota fiscal de serviço ou produto</p>
			</div>
			<div class="company-requirements__item">
				<mat-icon>insert_invitation</mat-icon>
				<p>Possui CNPJ há 6 meses ou mais</p>
			</div>
		</div>

		<button mat-flat-button onclick="window.open('https://register.broadfactor.com/cedente')" color="primary">ESTOU APTO E QUERO PARTICIPAR!</button>

	</section>

	<app-slider></app-slider>
	<app-carousel></app-carousel>

	<h1 style="font-size: 40px; font-weight: bold; text-align: center;">Planos</h1>
	<section class="container__plans">
		<div class="container__plans__item">
			<div>
				<h3 class="title">Starter</h3>
				<p class="price">R$ 0 <small>/mês</small></p>
				<p> --- </p>
				<p> --- </p>
			</div>
			<div class="discount">
				<p><b>Tarifa por operação</b></p>
				<h2 style="margin-bottom: 0;">R$ 15</h2>
			</div>
		</div>
		<div class="container__plans__item">
			<div>
				<h3 class="title primary">M.E.</h3>
				<p class="price">R$ 80 <small>/mês</small></p>
				<p> <mat-icon>check</mat-icon> 1 Consulta PJ</p>
				<p> --- </p>
			</div>
			<div class="discount success">
				<p><b>50% de desconto</b></p>
				<span>nos pagamentos até o vencimento</span>
			</div>
		</div>
		<div class="container__plans__item">
			<div>
				<h3 class="title primary">EPP</h3>
				<p class="price">R$ 150 <small>/mês</small></p>
				<p> <mat-icon>check</mat-icon> 5 Consultas PJ</p>
				<p> --- </p>
			</div>
			<div class="discount success">
				<p><b>50% de desconto</b></p>
				<span>nos pagamentos até o vencimento</span>
			</div>
		</div>
		<div class="container__plans__item">
			<div>
				<h3 class="title primary">Enterprise</h3>
				<p class="price">R$ 300 <small>/mês</small></p>
				<p> <mat-icon>check</mat-icon> 10 Consultas PJ</p>
				<p> --- </p>
			</div>
			<div class="discount success">
				<p><b>50% de desconto</b></p>
				<span>nos pagamentos até o vencimento</span>
			</div>
		</div>
		<div class="container__plans__item">
			<div>
				<h3 class="title">Escrow 1</h3>
				<p class="price">R$ 250 <small>/mês</small></p>
				<p> <mat-icon>check</mat-icon> 15 Consultas PJ</p>
				<p> <mat-icon>check</mat-icon> Gestão da conta</p>
			</div>
			<div class="discount">Sem desconto</div>
		</div>
		<div class="container__plans__item">
			<div>
				<h3 class="title">Escrow 2</h3>
			<p class="price">R$ 350 <small>/mês</small></p>
			<p> <mat-icon>check</mat-icon> 20 Consultas PJ</p>
			<p> <mat-icon>check</mat-icon> Gestão da conta</p>
			</div>
			<div class="discount">Sem desconto</div>
		</div>
	</section>
	<p style="font-size: 14px; text-align: center; padding: 0 40px;"><b>Sem fidelidade!</b>
		Nossos clientes estão com a gente porque gostam do nosso trabalho.​</p>

	<button style="margin: 40px auto 140px; padding: 10px 20px; display: block"
	onclick="window.open('https://register.broadfactor.com/cedente')"
	mat-flat-button color="primary">	QUERO ANTECIPAR MEUS RECEBÍVEIS	</button>


	<section class="container__faq">
		<h2>Ainda está com dúvidas?</h2>

		<mat-accordion>
			<mat-expansion-panel class="expansion-panel">
				<mat-expansion-panel-header>
					<mat-panel-title>
						Que tipo de recebível eu posso antecipar?
					</mat-panel-title>
				</mat-expansion-panel-header>
				<p>Vendas para pessoas jurídicas, sem restrições de crédito, com produtos ou serviços entregues e que confirmem os dados da nota fiscal por telefone ou e-mail.</p>
			</mat-expansion-panel>
			<mat-expansion-panel class="expansion-panel">
				<mat-expansion-panel-header>
					<mat-panel-title>
						A Broadfactor faz a antecipação de notas de Pessoas Físicas?
					</mat-panel-title>
				</mat-expansion-panel-header>
				<p>Não. A antecipação de recebíveis é uma operação que só pode acontecer entre uma factoring e uma outra pessoa jurídica. Pessoas físicas que necessitam de crédito, por exemplo, podem procurar auxílio em bancos e outras modalidades de instituições financeiras. </p>
			</mat-expansion-panel>
			<mat-expansion-panel class="expansion-panel">
				<mat-expansion-panel-header>
					<mat-panel-title>
						É possível antecipar notas de mercadorias ainda não entregues ou serviço ainda não prestado?
					</mat-panel-title>
				</mat-expansion-panel-header>
				<p>Nossos atendimentos são personalizados para cada empresa e, portanto, podem variar de acordo com as informações do seu negócio.</p>
			</mat-expansion-panel>
			<mat-expansion-panel class="expansion-panel">
				<mat-expansion-panel-header>
					<mat-panel-title>
						A Broadfactor antecipa notas de mercadorias ainda não entregues ou serviço ainda não prestado?
					</mat-panel-title>
				</mat-expansion-panel-header>
				<p>Não. A antecipação é destinada apenas para os recebíveis de vendas que foram realizadas, ou de serviços que foram prestados.</p>
			</mat-expansion-panel>
			<mat-expansion-panel class="expansion-panel">
				<mat-expansion-panel-header>
					<mat-panel-title>
						O limite inicial é permanente ou pode passar por atualização?
					</mat-panel-title>
				</mat-expansion-panel-header>
				<p>O limite pode ser aumentado conforme a demanda e relacionamento do cedente na plataforma.</p>
			</mat-expansion-panel>
			<mat-expansion-panel class="expansion-panel">
				<mat-expansion-panel-header>
					<mat-panel-title>
						Como funciona a antecipação?
					</mat-panel-title>
				</mat-expansion-panel-header>
				<p>Primeiro, você realiza o cadastro da sua empresa em nossa plataforma. Após a análise do comitê de segurança, será concedido um limite operacional para antecipar seus recebíveis. Depois disso, basta importar suas notas fiscais que são oferecidas às empresas de fomento parceiras. </p>
			</mat-expansion-panel>
			<mat-expansion-panel class="expansion-panel">
				<mat-expansion-panel-header>
					<mat-panel-title>
						A Broadfactor atende às notas de empenho?
					</mat-panel-title>
				</mat-expansion-panel-header>
				<p>Sim, através do programa <a href="https://antecipagov.com.br/ ">Antecipagov.</a> Somos credenciados pelo Governo Federal e estamos aptos para essa operação.</p>
			</mat-expansion-panel>
			<mat-expansion-panel class="expansion-panel">
				<mat-expansion-panel-header>
					<mat-panel-title>
						Antecipação de recebíveis é a mesma coisa que empréstimo?
					</mat-panel-title>
				</mat-expansion-panel-header>
				<p>Não. A maior diferença entre as duas modalidades é bem simples: empréstimos se referem a valores que não são seus e nem do seu negócio. Antecipação se trata de um dinheiro que é do empreendedor, mas seria recebido em sua totalidade apenas no futuro. Além disso, as taxas de juros dos empréstimos são bem altas em comparação à antecipação de recebíveis.</p>
			</mat-expansion-panel>
			<mat-expansion-panel class="expansion-panel">
				<mat-expansion-panel-header>
					<mat-panel-title>
						Quanto tempo leva para o cadastro ser aprovado e o cedente poder antecipar?
					</mat-panel-title>
				</mat-expansion-panel-header>
				<p>	Após o cadastro, nossa equipe entrará em contato para iniciar sua análise de crédito. Você receberá informações sobre o andamento e os passos seguintes através do WhatsApp ou por ligação. Quanto mais informações recebermos sobre sua empresa, mais rápido será a sua análise e ativação na plataforma.</p>
			</mat-expansion-panel>
			<mat-expansion-panel class="expansion-panel">
				<mat-expansion-panel-header>
					<mat-panel-title>
						Existe um valor mínimo ou máximo para a antecipação?
					</mat-panel-title>
				</mat-expansion-panel-header>
				<p> Não existem valores mínimos ou máximos para antecipações. Apenas, para valor máximo, precisa corresponder com o limite.</p>
			</mat-expansion-panel>
			<mat-expansion-panel class="expansion-panel">
				<mat-expansion-panel-header>
					<mat-panel-title>
						Sou um microempreendedor Individual (Mei) posso antecipar?
					</mat-panel-title>
				</mat-expansion-panel-header>
				<p> Não. Para poder antecipar, o usuário precisa atingir o valor mínimo de faturamento mensal de R$50.000,00 (durante 6 meses). </p>
			</mat-expansion-panel>
			<mat-expansion-panel class="expansion-panel">
				<mat-expansion-panel-header>
					<mat-panel-title>
						Se meu cliente não pagar, como funciona?
					</mat-panel-title>
				</mat-expansion-panel-header>
				<p> É de responsabilidade do cedente. Para que não fique inadimplente na plataforma, os títulos precisam ser liquidados. Se o sacado atrasa, o cedente passa a ser cobrado também. </p>
			</mat-expansion-panel>
		</mat-accordion>

	</section>


</div>


