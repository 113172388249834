import { JobVacancyService } from './job-vacancy.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';

@Component({
  selector: 'app-job-vacancy',
  templateUrl: './job-vacancy.component.html',
  styleUrls: ['./job-vacancy.component.scss'],
})
export class JobVacancyComponent implements OnInit {
  @ViewChild('form')
  form!: NgForm;
  file!: any;
  formCliente: FormGroup;
  sendedMail!: boolean;
  clickedButton!: boolean;
  acceptedTerms: boolean = false;

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private jobVacancyService: JobVacancyService
  ) {
    this.formCliente = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      candidacy: ['', Validators.required],
      msg: ['', Validators.required],
      file: ['', Validators.required],
      linkedIn: ['', Validators.required],
      acceptedTerms: [null, Validators.requiredTrue],
    });
  }

  ngOnInit() {}

  send() {
    const form = new FormData();
    form.append('name', this.formCliente.get('name')?.value);
    form.append('email', this.formCliente.get('email')?.value);
    form.append('phone', this.formCliente.get('phone')?.value);
    form.append('candidacy', this.formCliente.get('candidacy')?.value);
    form.append('msg', this.formCliente.get('msg')?.value);
    form.append('linkedIn', this.formCliente.get('linkedIn')?.value);
    form.append('file', this.file);

    this.jobVacancyService
      .sendJobVacancy(form as any)
      .pipe(take(1))
      .subscribe({
        next: () => ((this.sendedMail = true), (this.clickedButton = true)),
        error: () => ((this.sendedMail = false), (this.clickedButton = true)),
        complete: () => ((this.form.resetForm()), (this.removeDocument())),
      });
  }

  csvInputChange(fileInputEvent: any) {
    this.file = fileInputEvent.target.files[0];
  }
  checkbox(): void {
    this.acceptedTerms = !this.acceptedTerms;
  }
  removeDocument(): void {
    this.formCliente.get('file')?.patchValue(null);
    this.file = null;
  }
}
