<header>
	<div class="header desktop-view">
		<a rel="preload" [routerLink]="['']"><img alt="Broadfactor logo" src="../../../assets/img/logo.png"></a>
		<div>
			<button mat-button
				[matMenuTriggerFor]="menuSolutions">
				Soluções
				<mat-icon>arrow_drop_down</mat-icon>
			</button>
			<mat-menu #menuSolutions="matMenu">
				<button mat-menu-item class="menu-item"
					routerLink="solucoes/antecipar-recebiveis"
					routerLinkActive="mat-button mat-primary">
					<div>
						<span>Antecipar recebíveis</span>
					</div>
				</button>
				<button mat-menu-item class="menu-item"
					routerLink="solucoes/comprar-recebiveis"
					routerLinkActive="mat-button mat-primary">
					<div>
						<span>Comprar títulos</span>
					</div>
				</button>
				<button mat-menu-item class="menu-item"
					onclick="window.open('https://antecipagov.com.br/')" >
					<div>
						<span>AntecipaGov</span>
					</div>
				</button>
			</mat-menu>

			<button mat-button
				[matMenuTriggerFor]="menuCompany"
				routerLinkActive="active">A Broadfactor
				<mat-icon>arrow_drop_down</mat-icon></button>
			<mat-menu #menuCompany="matMenu">
				<button
					routerLink="broadfactor/sobre"
					routerLinkActive="mat-button mat-primary"
					mat-menu-item class="menu-item">
					Quem somos</button>
				<button mat-menu-item
					routerLink="broadfactor/trabalhe-conosco"
					routerLinkActive="mat-button mat-primary"
					class="menu-item">Trabalhe conosco</button>
			</mat-menu>

			<button mat-button
				routerLink="contatos"
				routerLinkActive="active"
				>Contato</button>
			<button mat-button onclick="window.open('https://broadfactor.com/blog/')">Blog</button>
			<button mat-button onclick="window.open('https://plataforma.broadfactor.com/')" color="primary">Login</button>
			<button mat-flat-button color="primary" onclick="window.open('https://register.broadfactor.com/cedente')">Pedir antecipação</button>

		</div>
	</div>
	<div class="header mobile-view">
		<a [routerLink]="['']"><img width="150px" alt="Broadfactor logo"  height="auto" src="../../../assets/img/logo.png"></a>

		<button mat-button [matMenuTriggerFor]="menuMd"><mat-icon>menu</mat-icon></button>

		<mat-menu #menuMd="matMenu" xPosition="before">
			<button mat-menu-item [matMenuTriggerFor]="menuSolutions" routerLink="solucoes"
			routerLinkActive="active">Soluções</button>
			<button mat-menu-item [matMenuTriggerFor]="menuCompany" routerLink="broadfactor"
			routerLinkActive="active">A Broadfactor</button>
			<button mat-menu-item routerLink="contatos"
			routerLinkActive="active">Contato</button>
			<button mat-menu-item onclick="window.open('https://broadfactor.com/blog/')">Blog</button>
			<button mat-menu-item onclick="window.open('https://plataforma.broadfactor.com/')" >Login</button>
			<button  color="primary" class="primary" mat-menu-item onclick="window.open('https://register.broadfactor.com/cedente')">Pedir antecipação</button>
		</mat-menu>

		<mat-menu #menuSolutions="matMenu">
			<button mat-menu-item routerLink="solucoes/antecipar-recebiveis"
			routerLinkActive="mat-button mat-primary">Antecipar recebíveis</button>
			<button mat-menu-item routerLink="solucoes/comprar-recebiveis"
			routerLinkActive="mat-button mat-primary">Comprar títulos</button>
			<button mat-menu-item onclick="window.open('https://antecipagov.com.br/')" >AntecipaGov</button>
		</mat-menu>

		<mat-menu #menuCompany="matMenu">
			<button mat-menu-item routerLink="broadfactor/sobre"
			routerLinkActive="mat-button mat-primary">Quem somos</button>
			<button mat-menu-item routerLink="broadfactor/trabalhe-conosco"
			routerLinkActive="mat-button mat-primary">Trabalhe conosco</button>
		</mat-menu>
	</div>
</header>
