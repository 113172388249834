<section class="container__title">
  <h1>Venha reescrever histórias com a Broadfactor! </h1>
</section>

<section class="container__home">
  <img src="./assets/img/nosso-proposito.png" width="400px" style="margin-right: 50px;" alt="">

  <div>
    <h1 class="home__title">Nosso propósito </h1>
      <br>
      <p>Nossa missão é simples. <b>Ajudar o empreendedor a crescer e prosperar seu negócio.</b></p>
      <p>Não “apenas” disponibilizamos crédito, mas sim nos envolvemos com o projeto, dando suporte a quem precisa e quer crescer mais. Isso está no nosso DNA. Somos apaixonados em fazer empresários prosperarem.</p>

  </div>

</section>

<section class="container-benefits">
  <div class="container-benefits__width">
    <div class="container-benefits__grid">
      <div class="container-benefits__box">
        <h2>Nossos Valores!</h2>
      </div>
      <div class="container-benefits__box">
        <img alt="Paixão" src="./assets/img/Paixao.svg">
        <h3>Paixão</h3>
        <span>Somos apaixonados pelo que fazemos. Só quem é apaixonado de verdade entrega o melhor de si mesmo.</span>
      </div>
      <div class="container-benefits__box">
        <img alt="Honestidade" src="./assets/img/Honestidade.svg">
        <h3>Honestidade</h3>
        <span>Presente no DNA da Broadfactor, esse é o principal valor em nosso mercado. Transparência acima de tudo.
        </span>
      </div>
      <div class="container-benefits__box">
        <img alt="Confiança" src="./assets/img/Confianca.svg">
        <h3>Confiança</h3>
        <span>Cumprimos o que lhe é prometido. Nosso serviço é de alta qualidade, com garantia e respeito de prazos.</span>
      </div>
      <div class="container-benefits__box">
        <img alt="Conexão" src="./assets/img/Conexao.svg">
        <h3>Conexão</h3>
        <span>Desde 2011 conectando compradores e vendedores de recebíveis para o negócio perfeito.</span>
      </div>
      <div class="container-benefits__box">
        <img alt="Excelência" src="./assets/img/Excelencia.svg">
        <h3>Excelência</h3>
        <span>Milhares de empreendedores confiam em nosso negócio para realizar seus sonhos, por isso não toleramos erros.</span>
      </div>
    </div>
  </div>
</section>

<section class="container__home">

  <div>
    <h1 class="home__title">Somos apaixonados por colaboração!</h1>
      <br>
     <p>Nossos Broaders seguem seu dia a dia de forma leve, organizada e séria, mantendo um equilíbrio entre a boa vivência e partilhando ideias que possam mudar a história de nossos usuários.</p>

     <p>Acreditamos em um futuro próspero para todas as empresas que antecipam conosco. Sabemos que somente juntos poderemos crescer e sermos cada vez mais fortes.</p>
  </div>
  <img src="./assets/img/Quem somos - 1.png" width="300px" style="margin-left: 100px;" alt="">

</section>

<section class="container__home">
  <img src="./assets/img/png/Trabalhe-de-onde-voce-quiser.png" width="300px" style="margin-right: 100px;" alt="">

  <div>
    <h1 class="home__title">Trabalhe de casa ou de onde quiser!</h1>
      <br>
     <p>Somos uma empresa totalmente adaptada ao conceito de Home-Office. Nosso time trabalha de casa ou de qualquer lugar onde você possa estar com seu Notebook, um celular e uma conexão à internet.
    </p>
  </div>

</section>

<section class="careers">
  <div>
    <h1>Confira nossas vagas:</h1>
    <p>E ajude a mudar a história de milhares de empreendedores.</p>

    <button mat-raised-button routerLink="talentos">Banco de talentos </button>
  </div>
</section>
