import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anticipate-receivables',
  templateUrl: './anticipate-receivables.component.html',
  styleUrls: ['./anticipate-receivables.component.scss']
})
export class AnticipateReceivablesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  slides = [
    {
      url: 'https://source.unsplash.com/1600x900/?nature,water'
    },
    {
      url: 'https://source.unsplash.com/1600x1600/?nature,forest'
    }
  ]
}
