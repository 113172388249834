<swiper [config]="config">
  <div class="swiper-wrapper">
    <div class="swiper-slide"><h2>Parceiros</h2></div>
    <div class="swiper-slide"><img [style.width]="'50px'" src="../../../assets/img/partners/aws.webp" alt="img 1"></div>
    <div class="swiper-slide"><img src="../../../assets/img/partners/Bry.svg" alt="img 2"></div>
    <div class="swiper-slide"><img src="../../../assets/img/partners/conta azul.png" alt="img 3"></div>
    <div class="swiper-slide"><img src="../../../assets/img/partners/cropped-Logo-Site-Somma.ai--768x219.png" alt="img 4"></div>
    <div class="swiper-slide"><img src="../../../assets/img/partners/Lexter.webp" alt="img 5"></div>
    <div class="swiper-slide"><img src="../../../assets/img/partners/Omie.webp" alt="img 6"></div>
    <div class="swiper-slide"><img src="../../../assets/img/partners/RD.webp" alt="img 6"></div>
    <div class="swiper-slide"><img src="../../../assets/img/partners/Serasa.webp" alt="img 6"></div>
    <div class="swiper-slide"><img src="../../../assets/img/partners/SERPRO.webp" alt="img 6"></div>
    <div class="swiper-slide"><img [style.width]="'70px'" src="../../../assets/img/partners/silva.webp" alt="img 6"></div>
    <div class="swiper-slide"><img src="../../../assets/img/partners/SRM.webp" alt="img 6"></div>

  </div>
  <!-- Add Pagination -->
  <div class="swiper-pagination bullet-partners"></div>
</swiper>