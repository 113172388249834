import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-buy-receivables',
  templateUrl: './buy-receivables.component.html',
  styleUrls: ['./buy-receivables.component.scss']
})
export class BuyReceivablesComponent implements OnInit {

  constructor(private _sanitizer: DomSanitizer) {}

  ngOnInit() {
  }

  getSVGImageUrl(image: any) {
    let base64string = btoa(image);
    return this._sanitizer.bypassSecurityTrustResourceUrl(
      `data:image/svg+xml;base64,${base64string}`
    );
  }



}
