import { ContactsBodyInterface } from './contacts-body.interface';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  constructor(private httpClient: HttpClient) {}

  contactUs(body: ContactsBodyInterface): Observable<string> {
    const bodyEmail = new FormData();
    bodyEmail.append('email', body.email)
    bodyEmail.append('name', body.name)
    bodyEmail.append('msg', body.msg)
    bodyEmail.append('subject', body.subject)
    return this.httpClient.post<string>(`${environment.api}/contact`, bodyEmail);
  }
}
