import { Component, Input, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-lottie',
  templateUrl: './lottie.component.html',
  styleUrls: ['./lottie.component.css']
})
export class LottieComponent implements OnInit {

  @Input() json!: string;
  options!: AnimationOptions;

  constructor() { }  

  ngOnInit(): void { 
    this.options = {    
      path: this.json ,
      loop: true 
    };  
  }

  // // This is the component function that binds to the animationCreated event from the package  
  // onAnimate(animationItem: AnimationItem): void {    
  //   console.log(animationItem);  
  // }

  // animationCreated(animationItem: AnimationItem): void {
  //   console.log(animationItem);
  // }

}
