import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { SwiperOptions } from 'swiper';



@Component({
	selector: 'app-carousel',
	templateUrl: './carousel.component.html',
	styleUrls: ['./carousel.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CarouselComponent implements AfterViewInit {

	config: SwiperOptions = {
		spaceBetween: 30,
		slidesPerView: 3,
		centeredSlides: true,
		roundLengths: true,
		loop: true,
		navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev"
		},
		breakpoints: {
			10: {
				slidesPerView: 1,
				spaceBetween: 5,
			},
			700: {
				slidesPerView: 1,
				spaceBetween: 5,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 10,
			},
		}
		// direction: 'horizontal',
		// loop: true,
		// allowTouchMove: false,
		// centeredSlides: true,
		// slidesPerView: 2,
		// navigation: {
		// 	nextEl: ".swiper-button-next",
		// 	prevEl: ".swiper-button-prev"
		//   },
		// pagination: {
		// 	el: '.swiper-pagination',
		// 	clickable: true
		// },
		// spaceBetween: 20,
		// breakpoints: {
		// 	// when window width is >= 320px
		// 	320: {
		// 		slidesPerView: 1,
		// 		spaceBetween: 10,
		// 	},
		// 	// when window width is >= 480px
		// 	480: {
		// 		slidesPerView: 1,
		// 		spaceBetween: 10,
		// 	},
		// 	// when window width is >= 640px
		// 	640: {
		// 		slidesPerView: 2,
		// 		spaceBetween: 20,
		// 	},
		// 	// when window width is >= 1024px
		// 	1024: {
		// 		slidesPerView: 2,
		// 		spaceBetween: 20,
		// 	},
		// 	// when window width is >= 1024px
		// 	1300: {
		// 		slidesPerView: 3,
		// 		spaceBetween: 20,
		// 	}
		// }
	}




	clients: any[] = [
		{
			name: 'Eduardo',
			role: 'Cedente Broadfactor',
			description: 'Minha empresa sempre foi bem pequena. Porém, estamos crescendo 2000% este ano. O atendimento de vocês está sendo excepcional, nem sei como agradecer. Por mais que neste momento eu use o dinheiro nas antecipações, sei que logo recupero na entrega dos produtos.'
		},
		{
			name: 'Marcones',
			role: 'Cedente Broadfactor',
			description: 'Cadastrei minha empresa aí com vocês faz anos. Se não fosse vocês minha empresa nem existia mais. E hoje, graças a Deus, esse tempo todo que a gente trabalha juntos a gente está crescendo e foi tudo pela ajuda de vocês. Com certeza a gente vai bem mais longe”'
		},
		{
			name: 'Eduardo',
			role: 'Financiador parceiro Broadfactor',
			description: 'O trabalho de vocês agrega valor para muitos empreendedores e indiretamente gera muitos empregos, contribuindo para a economia e continuidade da evolução do nosso país.  Neste ano pudemos ver com bons olhos a experiência que adquirimos e é com muita satisfação que até setembro alcancei um retorno líquido de 25,63% sobre nossa carteira.'
		},
		{
			name: 'Marcelo',
			role: 'Cedente Broadfactor',
			description: 'Nem sei como agradecer a vocês hoje. Salvaram minha semana! Pensa em uma pessoa apreensiva para pagar os funcionários. Muito obrigada pela parceria!'
		}
	]
	constructor(@Inject(PLATFORM_ID) private _platformId: Object) { }


	ngOnChanges(changes: SimpleChanges): void {
		if (isPlatformBrowser(this._platformId)) {
			this.config = {
				direction: 'horizontal',
				updateOnWindowResize: true,
			};
		}
	}

	ngAfterViewInit(): void {
		if (isPlatformBrowser(this._platformId)) {
			this.config = {
				direction: 'horizontal',
				updateOnWindowResize: true,
				preloadImages: false
			};
		}
	}
}
