import { ContactsService } from './contacts.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { take } from 'rxjs';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent implements OnInit {
  @ViewChild('formChild')
  formChild!: NgForm;
  file!: File;
  form: FormGroup;
  sendedMail!: boolean;
  clickedButton!: boolean;

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private contactsService: ContactsService
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      subject: ['', Validators.required],
      msg: ['', Validators.required],
    });
  }
  ngOnInit() {}

  send() {
    this.contactsService
      .contactUs(this.form?.value)
      .pipe(take(1))
      .subscribe({
        next: () => ((this.sendedMail = true), (this.clickedButton = true)),
        error: () => ((this.sendedMail = false), (this.clickedButton = true), this.form.markAllAsTouched()),
        complete: () => this.formChild.resetForm(),
      });
  }
}
